<template>
  <tr class="kt-datatable__row">
    <td data-field="name" class="kt-datatable__cell">
      <span>{{ dealer.name }}</span>
    </td>
    <td data-field="email" class="kt-datatable__cell">
      <span>{{ dealer.account_number }}</span>
    </td>
    <td data-field="to" class="kt-datatable__cell badge" :class="dealer.status">
      <span class="bagde-text">{{ dealer.status }}</span>
    </td>
    <td data-field="description" class="kt-datatable__cell">
      <span>{{ dealer.legacy_playbooks }}</span>
    </td>
    <td data-field="description" class="kt-datatable__cell">
      <span>{{ dealer.playbooks }}</span>
    </td>
    <td data-field="type" class="kt-datatable__cell">
      <span>{{ dealer.snapsheets }}</span>
    </td>
    <td data-field="type" class="kt-datatable__cell">
      <span>{{ dealer.showrooms }}</span>
    </td>
    <td data-field="type" class="kt-datatable__cell">
      <span>{{ dealer.quotes }}</span>
    </td>
    <td data-field="type" class="kt-datatable__cell">
      <span>{{ dealer.created }}</span>
    </td>
    <td data-field="type" class="kt-datatable__cell">
      <span>{{ dealer.last_seen }}</span>
    </td>
    <td data-field="type" class="kt-datatable__cell impersonate">
      <a target="_blank" title="Impersonate" :href="dealer.impersonate_link">
        <span class="mr-1">Impersonate</span>
        <img
          src="/images/external-link-table.png"
          :alt="'external link to impersonate as ' + dealer.name"
        />
      </a>
    </td>
  </tr>
</template>

<script>
export default {
  name: "MyDealersTableRow",
  props: ["dealer"],
  computed: {},
  methods: {},
};
</script>

<style lang="scss" >
.kt-datatable__body {
  tr.kt-datatable__row:hover {
    background-color: #aaaaff;
    *:not(.bagde-text) {
      color: white !important;
    }
    .impersonate a span {
      color: white !important;
    }
  }

  td.kt-datatable__cell {
    padding: 0 !important;
    /* padding-right: 5px !important; */
    text-align: center;
    &.badge {
      span {
        padding: 0.5rem !important;
      }
    }
  }
}
</style>