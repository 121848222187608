<template>
  <div class="alert fade show col-12 col-lg-3 mb-mobile-4" @click.stop="handleHelpTextOpen()" role="alert">
    <div class="alert-icon">
      <p>Help</p>
      <i class="kt-font-brand flaticon-questions-circular-button"></i>
    </div>
    <article class="text-container" :class="{ open: isHelpTextOpen }">
      <p class="text">
        {{ text }}
      </p>
    </article>
  </div>
</template>

<script>
export default {
  name: "HelpTextTooltip",
  props: ["text", 'isPlacedInFilters'],
  data() {
    return {
      isHelpTextOpen: false,
    };
  },
  methods: {
    handleHelpTextOpen() {
      this.isHelpTextOpen = !this.isHelpTextOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  margin-left: auto;
  border-radius: 4px;
  cursor: pointer;

  .alert-icon {
    display: flex;
    align-items: center;

    p {
      margin: 0;
      padding: 0.5rem;
      color: black;
      font-size: 1.2rem;
      color: black;
    }

    &:hover i::before {
      box-shadow: 0 0 10px 1px #389ef3;
      border-radius: 51%;
    }
  }

  .alert-icon {
    padding: 0;

    i::before {
      transition: all 0.2s;
    }
  }

  .text-container {
    position: absolute;
    width: 50vw;
    right: 0.5rem;
    top: 4rem;
    background: #1a1919;
    cursor: initial;
    pointer-events: none;
    z-index: 100;
    padding: 0.7rem;
    color: white;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 0 5px 5px #00000047;
    transform: scale(1, 0);
    transform-origin: top;
    transition: all 0.2s;
    &.open {
      transform: scale(1);
    }

    .text {
      margin: 0;
      line-height: 2rem;
      color: white !important;
    }

    @media screen and (max-width: 768px) {
      width: 65vw;
      padding: 1rem;
    }
  }
}
</style>