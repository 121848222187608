<template>
  <!--begin::Pagination-->
  <div
    class="row justify-content-between align-items-center flex-wrap pagination"
  >
    <div class="d-flex flex-wrap py-2 mr-md-3 col-12">
      <!-- Pagination links-->

      <!-- Prev Button -->
      <a
        @click="selected_page !== 1 && emitChangePage(selected_page - 1)"
        href="javascript:;"
        class="btn btn-icon btn-sm border-0 mr-2 my-1 arrow-btn"
        :class="{
          'btn-light ': isFirstPageSelected,
          'btn-hover-primary ': !isFirstPageSelected,
        }"
      >
        <span aria-label="previous page">«</span>
      </a>

      <!-- First link -->
      <a
        @click="emitChangePage(1)"
        href="javascript:;"
        :class="{ active: 1 === selected_page }"
        class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1 btn-pagination-link"
      >
        1
      </a>

      <!-- Empty dots -->
      <a
        v-if="showFirstDots"
        href="#"
        class="btn btn-icon btn-sm border-0 btn-light mr-2 my-1 dots"
        >...</a
      >

      <!-- Middle Links -->
      <template v-if="total_pages > 1">
        <a
          v-for="pageNumber in middlePages"
          :key="'page-' + pageNumber"
          @click="emitChangePage(pageNumber)"
          href="javascript:;"
          :class="{ active: pageNumber === selected_page }"
          class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1 btn-pagination-link"
          >{{ pageNumber }}
        </a>
      </template>

      <!-- Empty dots -->
      <a
        v-if="showLastDots"
        href="#"
        class="btn btn-icon btn-sm border-0 btn-light mr-2 my-1 dots"
        >...</a
      >

      <!-- Last Link -->
      <template v-if="total_pages > 1">
        <a
          @click="emitChangePage(total_pages)"
          href="javascript:;"
          :class="{ active: total_pages === selected_page }"
          class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1 btn-pagination-link"
        >
          {{ total_pages }}
        </a>
      </template>

      <!-- Next Button -->
      <a
        @click="!isLastPageSelected && emitChangePage(selected_page + 1)"
        href="javascript:;"
        class="btn btn-icon btn-sm border-0 mr-2 my-1 arrow-btn"
        :class="{
          'btn-light': isLastPageSelected,
          'btn-hover-primary ': !isLastPageSelected,
        }"
      >
        <span aria-label="next page">»</span>
      </a>
    </div>
  </div>
  <!--end:: Pagination-->
</template>

<script>
export default {
  name: "Pagination",
  props: ["total_pages", "selected_page", "total_items"],
  computed: {
    middlePages: function () {
      //This function creates an array with the page's links
      function linksArrayRange(start, end) {
        return Array(end - start + 1)
          .fill()
          .map((_, idx) => start + idx);
      }

      if (!this.total_pages) return [];

      if (this.total_pages && this.total_pages > 5) {
        //Return an array with 3 pages, they will be the links
        let links;
        //First 3 links
        if (this.selected_page <= 3) {
          links = linksArrayRange(2, 4);
        } else if (this.selected_page >= this.total_pages - 2) {
          //Last 3 links
          links = linksArrayRange(this.total_pages - 3, this.total_pages - 1);
        } else {
          links = linksArrayRange(
            this.selected_page - 1,
            this.selected_page + 1
          );
        }
        return links;
      } else {
        if (this.total_pages > 1) {
          return linksArrayRange(2, this.total_pages - 1);
        } else {
          return [];
        }
      }
    },

    showFirstDots: function () {
      return this.total_pages > 5 && this.selected_page > 3;
    },

    showLastDots: function () {
      return this.total_pages > 5 && this.selected_page < this.total_pages - 2;
    },

    isFirstPageSelected() {
      return this.selected_page === 1;
    },

    isLastPageSelected() {
      return this.selected_page === this.total_pages;
    },
  },
  methods: {
    emitChangePage(pageNumber) {
      this.$emit("on-set-page", pageNumber);
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  * {
    color: white;
  }
  .arrow-btn {
    span {
      color: black !important;
    }
  }
  .pagination {
    border: 4px solid #f3f6f9;
    margin: 5px 0;

    .btn-light {
      cursor: initial;
    }

    .dots {
      color: black;
    }

    .btn-pagination-link {
      color: black;

      &.active,
      &:hover {
        color: white;
      }
    }
  }
}
</style>