
<template>
  <div class="kt-portlet">
    <div class="kt-portlet__body">
      <!-- Sort -->
      <article
        class="kt-portlet__body kt-portlet__body--fit sort-header row flex-row"
      >
        <h4 class="sort-by col-12 col-md-6 mb-3 mb-md-0">My Dealers</h4>
        <!-- Sort By Dropdown -->
        <div class="sort-by col-12 col-md-6">
          <div
            class="flex row align-items-center justify-content-around no-gutters"
          >
            <label class="col-lg-4 text-lg-center mb-0" for="tl-"
              >Sort by</label
            >
            <select
              class="form-control col-lg-8"
              :value="sortedByValue.value"
              @change="emitSortBy"
            >
              <option
                v-for="opt in sortedByOptions"
                :key="opt.value + '-sort'"
                :value="opt.value"
              >
                {{ opt.label }}
              </option>
            </select>
          </div>
        </div>
      </article>

      <!-- Table body -->
      <article class="kt-portlet__body kt-portlet__body--fit">
        <div
          class="kt_datatable kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded"
          id="my-dealers_table"
        >
          <table class="kt-datatable__table table-striped">
            <thead class="kt-datatable__head">
              <tr class="kt-datatable__row">
                <th data-field="name_and_job" class="kt-datatable__cell">
                  <span>Dealer Name</span>
                </th>
                <th data-field="email" class="kt-datatable__cell">
                  <span>Account Number</span>
                </th>
                <th data-field="to" class="kt-datatable__cell">
                  <span>Status</span>
                </th>
                <th data-field="description" class="kt-datatable__cell">
                  <span>Legacy Playbooks</span>
                </th>
                <th data-field="description" class="kt-datatable__cell">
                  <span>Playbooks</span>
                </th>
                <th data-field="type" class="kt-datatable__cell">
                  <span>Snapsheets</span>
                </th>
                <th data-field="type" class="kt-datatable__cell">
                  <span>Showrooms</span>
                </th>
                <th data-field="type" class="kt-datatable__cell">
                  <span>Quotes</span>
                </th>
                <th data-field="type" class="kt-datatable__cell">
                  <span>Created</span>
                </th>
                <th data-field="type" class="kt-datatable__cell">
                  <span>Last Seen</span>
                </th>
                <th
                  data-field="Actions"
                  data-autohide-disabled="false"
                  class="kt-datatable__cell"
                >
                  <span style="width: 110px">Actions</span>
                </th>
              </tr>
            </thead>
            <tbody class="kt-datatable__body">
              <MyDealersTableRow
                v-for="(dealer, index) in dealers"
                :key="index + '-dealer'"
                :dealer="dealer"
              />
            </tbody>
          </table>
        </div>
      </article>

      <Pagination
        :total_pages="dealersTotalPages"
        :selected_page="actualPage"
        :total_items="3"
        v-on="{ 'on-set-page': emitChangePage }"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import MyDealersTableRow from "@/components/MyDealers/MyDealersTableRow.vue";
import Pagination from "@/components/Utils/Pagination.vue";
export default {
  name: "MyDealersTable",
  components: { MyDealersTableRow, Pagination },

  props: ["actualPage", "sortedByOptions", "sortedByValue"],
  computed: {
    ...mapState(["dealers", "dealersTotalPages"]),
  },

  methods: {
    emitSortBy(e) {
      const sortOpt = e.target.value;
      this.$emit("on-sort-by", sortOpt);
    },
    emitChangePage(pageNumber) {
      this.$emit("on-set-page", pageNumber);
    },
  },
};
</script>

<style>
.sort-header {
  padding: 1rem 0 !important;
  padding-bottom: 2rem !important;
  border-bottom: 2px solid #efeff5 !important;
  align-items: center;
}
</style>