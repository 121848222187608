<template>
  <div class="my-dealers">
    <!-- Head of the container (Search) -->
    <div id="my-dealers-nav" class="kt-portlet">
      <div class="kt-portlet__body my-dealers-nav-content">
        <div class="my-dealers-nav-container">
          <div
            class="my-dealers-nav row no-gutters align-center justify-content-between"
          >
            <!-- Searchbar -->
            <div
              class="kt-input-icon kt-input-icon--left col-12 col-lg-3 mb-mobile-4"
            >
              <input
                type="text"
                class="form-control"
                placeholder="Search..."
                id="message-search"
                v-model="searchQuery"
              />
              <span class="kt-input-icon__icon kt-input-icon__icon--left">
                <span>
                  <i class="la la-search"></i>
                </span>
              </span>
            </div>

            <!-- Buttons -->
            <div
              class="col-12 col-lg-6 mb-mobile-4 align-center d-flex flex-column flex-sm-row justify-content-around"
            >
              <router-link to="/invitation" class="link mb-3 mb-sm-0"
                >Send New Gameplan Invitation</router-link
              >
              <button class="link" @click="handleDownloadReport">
                Download Dealer Report
              </button>
            </div>

            <HelpTextTooltip :text="helpText" :isPlacedInFilters="true" />
          </div>
        </div>
      </div>
    </div>

    <MyDealersTable
      v-if="!loading"
      :actualPage="actualPage"
      :sortedByValue="actualSortedBy"
      :sortedByOptions="sortedByOptions"
      @on-sort-by="handleSortedBy"
      @on-set-page="handleChangePage"
    />

    <!-- Loader -->
    <Loader v-else />

    <!-- No Dealers -->
    <div class="row" v-if="!loading && dealers.length === 0">
      <div class="col">
        <div class="alert alert-light alert-elevate fade show" role="alert">
          <div class="alert-icon">
            <i class="kt-font-brand flaticon-warning"></i>
          </div>
          <div class="alert-text">There are no my-dealers yet.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import MyDealersTable from "@/components/MyDealers/MyDealersTable.vue";
import HelpTextTooltip from "@/components/Utils/HelpTextTooltip.vue";
import Loader from "@/components/Utils/Loader.vue";
import { mapActions, mapState } from "vuex";
import _ from "lodash";

export default {
  name: "MyDealers",
  components: {
    MyDealersTable,
    HelpTextTooltip,
    Loader,
  },

  data() {
    return {
      loading: true,

      searchQuery: "",

      actualPage: 1,

      actualSortedBy: {
        label: "Date Created (Newer First)",
        value: "date-newer",
      },
      sortedByOptions: [
        { label: "Date Created (Newer First)", value: "date-newer" },
        { label: "Date Created (Oldest First)", value: "date-oldest" },
        { label: "Last Seen (Newer First)", value: "seen-newer" },
        { label: "Last Seen (Oldest First)", value: "seen-oldest" },
        { label: "Dealer Name (A to Z)", value: "a-to-z" },
        { label: "Dealer Name (Z to A)", value: "z-to-a" },
        { label: "Account Number (Low to High)", value: "account-low" },
        { label: "Account Number (High to Low)", value: "account-high" },
      ],
      helpText:
        "Have questions on how to use this panel? Please email us at gameplan@foundersportgroup.com",
    };
  },

  computed: {
    ...mapState(["dealers", "token", "baseEndpoint"]),
  },

  methods: {
    ...mapActions(["getDealers"]),

    handleGetDealers: _.debounce(async function () {
      this.loading = true;
      await this.getDealers({
        page: this.actualPage,
        sortBy: this.actualSortedBy.value,
        searchQuery: this.searchQuery,
      });
      this.loading = false;
    }, 300),

    handleDownloadReport() {
      this.loading = true;
      axios({
        url:
          this.baseEndpoint + "/download-dealer-report?api_token=" + this.token,
        method: "GET",
      })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "gameplan-report.csv");
          document.body.appendChild(fileLink);

          this.loading = false;
          fileLink.click();
          this.$toastr.s("Report downloaded");
        })
        .catch(() => {
          this.$toastr.e("There was an error, please try again");
        });
    },

    handleSortedBy(valueSelected) {
      const sortOptionSelected = this.sortedByOptions.filter(
        (opt) => opt.value === valueSelected
      );
      this.actualSortedBy = sortOptionSelected[0];
    },

    handleChangePage(pageNumber) {
      this.actualPage = pageNumber;
    },
  },

  async mounted() {
    window.scrollTo({
      top: document.getElementById("kt_header").offsetTop,
      behavior: "smooth",
    });

    await this.handleGetDealers();
  },

  watch: {
    async actualSortedBy() {
      await this.handleGetDealers();
    },

    async actualPage() {
      await this.handleGetDealers();
    },

    async searchQuery() {
      await this.handleGetDealers();
    },
  },
};
</script>

<style lang="scss">
.my-dealers {
  #my-dealers_table {
    @media screen and (max-width: 1024px) {
      overflow-x: scroll;
    }

    table {
      @media screen and (max-width: 1024px) {
        width: 200%;
        border: 4px solid #f3f3f3;
      }
      @media screen and (max-width: 576px) {
        width: 250%;
        border: 4px solid #f3f3f3;
      }

      th.kt-datatable__cell span {
        font-weight: 700;
      }

      tr.kt-datatable__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-height: 100px;
        min-height: 55px;
        td,
        th {
          width: 100px;
          span {
            word-break: break-word;
            text-align: center;
          }
        }

        td {
          text-transform: capitalize;
          font-weight: bold;

          span,
          a,
          svg {
            color: currentColor;
          }

          &.impersonate {
            color: #808ce5 !important;

            a {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
          }

          &.lapsed {
            color: rgb(255, 197, 155) !important;
            background-color: rgb(255, 248, 232);
          }

          &.active {
            color: rgb(127, 216, 219) !important;
            background-color: rgb(232, 249, 248);
          }

          &.inactive {
            color: rgb(253, 57, 122) !important;
            background-color: rgb(255, 235, 241);
          }
        }

        td:not(.kt-datatable__cell) {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 55px;

          a {
            margin-top: auto;
            margin-bottom: auto;
          }
        }
      }
    }
  }

  .filter-by-user {
    display: flex;
    align-items: center;
  }

  .mb-mobile-4 {
    @media screen and (max-width: 1024px) {
      margin-bottom: 1.2rem;
    }
  }

  .my-dealers-nav {
    .link {
      background: white;
      border: 2px solid black;
      border-radius: 4px;
      font-weight: bold;
      font-size: 1.2rem;
      padding: 0.5rem 2rem;
      transition: all 0.2s;

      &:hover {
        background: black;
        color: white !important;
      }
    }
  }
}
</style>